import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import ULItemLayout from 'src/components/10-atoms/content/ULItemLayout';
import AudioIcon from 'src/components/10-atoms/icons/AudioIcon';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import FitImage from 'src/components/10-atoms/image/FitImage';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const PlaylistIntroDrawer = ({ onCancel, onGetStarted, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Speech Introduction')),
        React.createElement(BottomDrawerLayout.Body, { mb: theme.spacing.standard.md },
            React.createElement(FitImage, { source: { uri: AppAssets.ttsIntro } }),
            React.createElement(Column, { mt: theme.spacing.standard.lg },
                React.createElement(Row, { xAxis: "center", pv: theme.spacing.standard.md },
                    React.createElement(Typography, { align: "center", variant: TypographyVariant.PAGE_TITLE }, "Why read, when you can listen \uD83C\uDF89\uD83C\uDF89\uD83C\uDF89")),
                React.createElement(Column, { spacing: theme.spacing.standard.sm },
                    React.createElement(Typography, null, "You can do it in 3 easy steps:"),
                    React.createElement(ULItemLayout, null,
                        React.createElement(Row, { spacing: theme.spacing.standard.xs },
                            React.createElement(FeatherIcon, { name: "file-text" }),
                            React.createElement(Typography, null, "Open Question Paper or a Topic"))),
                    React.createElement(ULItemLayout, null,
                        React.createElement(Row, { spacing: theme.spacing.standard.xs },
                            React.createElement(FeatherIcon, { name: "play-circle" }),
                            React.createElement(Typography, null, "Click on Play Icon"))),
                    React.createElement(ULItemLayout, null,
                        React.createElement(Row, { xAxis: "center", yAxis: "flex-start", spacing: theme.spacing.standard.xs },
                            React.createElement(AudioIcon, null),
                            React.createElement(Typography, null, "Sit back, relax and listen")))))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { variant: "text", onPress: onCancel }, translate('Later')),
            React.createElement(Button, { onPress: onGetStarted }, translate('Try it now')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default PlaylistIntroDrawer;
