const AppAssets = {
    emptySelection: 'https://assets.marinesamraj.com/app-assets/empty_selection.png',
    abuseInspect: 'https://assets.marinesamraj.com/app-assets/abuse_inspect.png',
    sendToCommunty: 'https://assets.marinesamraj.com/app-assets/send_to_community.png',
    communityAddAnswerSuccess: 'https://assets.marinesamraj.com/app-assets/add_answer_success.png',
    noAnswersYet: 'https://assets.marinesamraj.com/app-assets/no_answers_yet.png',
    emptyRecommendations: 'https://assets.marinesamraj.com/app-assets/empty_recommendation.png',
    communityHowItWorks1: 'https://assets.marinesamraj.com/app-assets/how_it_works_1.png',
    communityHowItWorks2: 'https://assets.marinesamraj.com/app-assets/how_it_works_2.png',
    communityHowItWorks3: 'https://assets.marinesamraj.com/app-assets/how_it_works_3.png',
    communityHowItWorks4: 'https://assets.marinesamraj.com/app-assets/how_it_works_4.png',
    communityConnection: 'https://assets.marinesamraj.com/app-assets/marine_community.png',
    emptyNotifications: 'https://assets.marinesamraj.com/app-assets/no_notifications.png',
    notificationMarkRead: 'https://assets.marinesamraj.com/app-assets/mark_notification_read_demo.png',
    reputationEmptyBadges: 'https://assets.marinesamraj.com/app-assets/no_badges_yet.png',
    notificationMarkAllRead: 'https://assets.marinesamraj.com/app-assets/mark_all_notifications_read_demo.png',
    termsOfService: 'https://assets.marinesamraj.com/production/terms_of_service.html',
    privacyPolicy: 'https://assets.marinesamraj.com/production/privacy_policy.html',
    blockedUsersEmptyList: 'https://assets.marinesamraj.com/app-assets/blocked_users_empty_list.png',
    writeAnswerIllustration: 'https://assets.marinesamraj.com/app-assets/write_answer_illustration.png',
    sentForReviewIllustration: 'https://assets.marinesamraj.com/app-assets/sent_for_review_illustration.png',
    reviewPipelineTasksCompleted: 'https://assets.marinesamraj.com/app-assets/queue_tasks_completed.png',
    logo_512_512: 'https://assets.marinesamraj.com/app-assets/logo_512x512.png',
    ttsIntro: 'https://assets.marinesamraj.com/app-assets/tts_intro.png',
    stcServerCosts: 'https://assets.marinesamraj.com/app-assets/server_costs.png',
    stcExploreNewTech: 'https://assets.marinesamraj.com/app-assets/explore_new_tech.png',
    stcBuildNewFeatures: 'https://assets.marinesamraj.com/app-assets/build_new_features.png',
    stcFreeForever: 'https://assets.marinesamraj.com/app-assets/free_forever.png',
    stcEarnIncome: 'https://assets.marinesamraj.com/app-assets/earn_income.png',
    stcThankYou: 'https://assets.marinesamraj.com/app-assets/stc_thank_you.png',
};
// https://storage.googleapis.com/assets.marinesamraj.com/app-assets/no_notifications.png
export default AppAssets;
