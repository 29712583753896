import React from 'react';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Column from '../layout/Column';
import RowCenter from '../layout/RowCenter';
import Typography from '../typography/Typography';
export default function withNotificationCount(Component) {
    const WithNotificationCount = ({ notificationCount, size, ...rest }) => {
        const [{ styles }] = useTheme(stylesGenerator);
        return (React.createElement(Column, { style: { position: 'relative' } },
            React.createElement(Component, { size: size, ...rest }),
            !!notificationCount && (React.createElement(RowCenter, { brd: size, bg: "red", ph: 2, style: styles.notificationCount },
                React.createElement(Typography, { variant: TypographyVariant.ICON_BADGE }, notificationCount)))));
    };
    return WithNotificationCount;
}
const stylesGenerator = createThemedStyle(() => ({
    notificationCount: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
}));
