import { getFontWeightStyle } from 'src/components/10-atoms/typography/utils';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import { MathFieldRenderer } from './formats/MathField';
export default function useStyles(containerId, { typographyFontWeight, typographyVariant = TypographyVariant.READER, }) {
    const [{ theme }] = useTheme();
    const typographyVariantStyle = theme.typography[typographyVariant].style;
    const typographyFontWeightStyle = getFontWeightStyle(typographyFontWeight);
    return `
    #${containerId} .ql-editor {
      overflow-y: visible;
      height: auto;
      background-color: ${theme.palette.background.main};
      color: ${theme.palette.typography.primary};
      padding: 0;
      padding-bottom: 0;

      /* Typography styles */
      font-family: ${typographyFontWeightStyle.fontFamily ?? "'Montserrat', sans-serif"};
      
      ${typographyFontWeightStyle.fontWeight
        ? `font-weight: ${typographyFontWeightStyle.fontWeight};`
        : '500'}

      ${typographyVariantStyle.fontSize
        ? `font-size: ${typographyVariantStyle.fontSize}px;`
        : ''}
      
      ${typographyVariantStyle.lineHeight
        ? `line-height: ${typographyVariantStyle.lineHeight}px;`
        : ''}
      
      ${typographyVariantStyle.wordSpacing
        ? `word-spacing: ${typographyVariantStyle.wordSpacing}px;`
        : ''}
      
      ${typographyVariantStyle.letterSpacing
        ? `letter-spacing: ${typographyVariantStyle.letterSpacing}px;`
        : ''}
    }
      
    #${containerId} .ql-editor.ql-blank::before {
      color: ${theme.palette.typography.placeholder};
    }
      
    #${containerId} .ql-editor[contenteditable="true"] {
      padding: ${theme.spacing.standard.xs}px ${theme.spacing.page}px;
    }

    @keyframes loader-rotate {
      0% {
        transform: rotateZ(0deg);
      }
      100% {
        transform: rotateZ(360deg);
      }
    }

    #${containerId} .ql-editor .image-loader-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${theme.palette.backdrop};
      border-radius: ${theme.borderRadius.standard.md}px;
    }
    
    #${containerId} .ql-editor .image-loader-container .image-loader {
      width: ${theme.borderRadius.standard.xxxl}px;
      height: ${theme.borderRadius.standard.xxxl}px;
      border-radius: ${theme.borderRadius.standard.xxxl}px;
      border: 4px solid ${theme.palette.background.darker};
      border-top: 4px solid ${theme.palette.primary.dark};
      animation: loader-rotate 1s linear infinite;
    }            

    #${containerId} .ql-editor a {
      color: ${theme.palette.typography.link}
    }

    #${containerId} span:has(> div.${MathFieldRenderer.className}), s:has(> div.${MathFieldRenderer.className}) {
      display: inline-block;
    }

    /* Quill-Better-Table related styles*/

    #${containerId} .quill-better-table, .quill-better-table th, .quill-better-table td {
      border: 1px solid ${theme.palette.typography.primary} !important;
    }

    #${containerId} .qlbt-operation-menu {
      width: 240px !important;

      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    #${containerId} .qlbt-operation-menu .qlbt-operation-menu-item {
      background-color: ${theme.palette.background.dark};
      color: ${theme.palette.typography.primary};
    }

    #${containerId} .qlbt-operation-menu .qlbt-operation-menu-icon path {
      fill: ${theme.palette.typography.primary};
    }
      
    #${containerId} .qlbt-operation-menu .qlbt-operation-menu-dividing {
      background-color: ${theme.palette.background.darker};
    }

    #${containerId} .ql-editor .${MathFieldRenderer.className} {
      overflow-x: auto;
      overflow-y: hidden;
      height: max-content;
    }

    #${containerId} .ql-editor .${MathFieldRenderer.className} ML__strut--bottom {
      vertical-align: 0 !important;
    }
  `;
}
