import React, { useRef, useState } from 'react';
import Tag from 'src/components/10-atoms/Tag';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import InputLayout from '../InputLayout';
import TextInputBase from '../TextInputBase';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { duplicateRef } from 'src/utils/react';
const TagsInput = React.forwardRef(({ value, LeftIcon, RightIcon, leftIconColor, rightIconColor, disabled, onChange, onFocus, onBlur, style, input, onInputChange, inputRef, onKeyPress, onLayout, ...textInputProps }, ref) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const textInputRef = useRef(null);
    const [focused, setFocused] = useState(false);
    function handleFocus() {
        setFocused(true);
        onFocus && onFocus();
    }
    function handleBlur() {
        setFocused(false);
        onBlur && onBlur();
    }
    const removeTag = (index) => {
        const valueCopy = value?.slice();
        valueCopy?.splice(index, 1);
        onChange && onChange([...(valueCopy ?? [])]);
    };
    const handleSubmit = () => {
        const newTag = input.trim();
        if (newTag.length > 0) {
            const tagExists = (value?.findIndex(item => item.toLocaleLowerCase() === newTag.toLocaleLowerCase()) ?? -1) > -1;
            if (tagExists) {
                snackbarHelperActions.show({
                    variant: 'NEUTRAL',
                    duration: 'SHORT',
                    message: `Tag "${newTag}" already exists`,
                });
            }
            else {
                onChange && onChange(value ? value.concat(newTag) : [newTag]);
                onInputChange('');
            }
        }
    };
    const handleKeyPress = (event) => {
        event.persist();
        if (event.nativeEvent.key === 'Backspace') {
            if (input.length === 0) {
                removeTag((value?.length ?? 0) - 1);
            }
        }
        onKeyPress?.(event);
        return null;
    };
    return (React.createElement(InputLayout, { ref: ref, wrap: true, onLayout: onLayout, style: [styles.container, style], LeftIcon: LeftIcon, leftIconColor: leftIconColor, RightIcon: RightIcon, rightIconColor: rightIconColor, focused: focused, yAxis: "flex-start", onPress: () => textInputRef.current?.focus() },
        Array.isArray(value) &&
            value.map((tag, index) => (React.createElement(Tag, { key: tag, style: styles.tag, onRemove: () => removeTag(index), removable: true }, tag))),
        React.createElement(TextInputBase, { ref: duplicateRef(textInputRef, inputRef), onFocus: handleFocus, onBlur: handleBlur, value: input, onChangeText: onInputChange, returnKeyLabel: "Add", enterKeyHint: "done", onSubmitEditing: handleSubmit, onKeyPress: handleKeyPress, blurOnSubmit: false, editable: !disabled, style: styles.input, autoCapitalize: "none", autoCorrect: false, ...textInputProps })));
});
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        minHeight: 24 + 2 * theme.spacing.standard.xs + 2,
        paddingBottom: theme.spacing.standard.xs,
    },
    input: {
        flex: 1,
        minWidth: 120,
        marginTop: 8,
        minHeight: 24,
        height: 24,
        paddingVertical: 0,
        textAlignVertical: 'top',
    },
    tag: {
        marginTop: 8,
        marginRight: 8,
    },
}));
export default TagsInput;
