import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
const SUBJECT_DETAILS_QUERY = gql `
  query EP_SubjectDetails($subjectID: ID!) {
    ep_subjectDetails(subjectID: $subjectID) {
      id
      data {
        title
      }
      type
      ancestorsDetails {
        examLevel {
          data {
            title
          }
        }
      }
    }
  }
`;
export function useSubjectDetailsQuery(subjectID, options) {
    return useGQLQuery(SUBJECT_DETAILS_QUERY, {
        variables: {
            subjectID,
        },
        ...options,
    });
}
