import { StyleSheet } from 'react-native';
export var TypographyVariant;
(function (TypographyVariant) {
    TypographyVariant[TypographyVariant["HEADING"] = 1] = "HEADING";
    TypographyVariant[TypographyVariant["SUB_HEADING"] = 2] = "SUB_HEADING";
    TypographyVariant[TypographyVariant["PAGE_TITLE"] = 3] = "PAGE_TITLE";
    TypographyVariant[TypographyVariant["BODY_TITLE"] = 4] = "BODY_TITLE";
    TypographyVariant[TypographyVariant["BODY"] = 5] = "BODY";
    TypographyVariant[TypographyVariant["CAPTION"] = 6] = "CAPTION";
    TypographyVariant[TypographyVariant["PARAGRAPH"] = 7] = "PARAGRAPH";
    TypographyVariant[TypographyVariant["BADGE"] = 8] = "BADGE";
    TypographyVariant[TypographyVariant["ICON_BADGE"] = 9] = "ICON_BADGE";
    TypographyVariant[TypographyVariant["READER"] = 10] = "READER";
    TypographyVariant[TypographyVariant["EMPTY_MSG"] = 11] = "EMPTY_MSG";
    TypographyVariant[TypographyVariant["ERROR_MSG"] = 12] = "ERROR_MSG";
})(TypographyVariant || (TypographyVariant = {}));
export var TypographyFontWeight;
(function (TypographyFontWeight) {
    TypographyFontWeight[TypographyFontWeight["THIN"] = 1] = "THIN";
    TypographyFontWeight[TypographyFontWeight["LIGHT"] = 2] = "LIGHT";
    TypographyFontWeight[TypographyFontWeight["REGULAR"] = 3] = "REGULAR";
    TypographyFontWeight[TypographyFontWeight["MEDIUM"] = 4] = "MEDIUM";
    TypographyFontWeight[TypographyFontWeight["BOLD"] = 5] = "BOLD";
})(TypographyFontWeight || (TypographyFontWeight = {}));
export const typography = ({ palette, }) => ({
    [TypographyVariant.HEADING]: {
        style: StyleSheet.flatten({
            fontSize: 24,
            lineHeight: 36,
        }),
        fontWeight: TypographyFontWeight.MEDIUM,
    },
    [TypographyVariant.SUB_HEADING]: {
        style: StyleSheet.flatten({
            fontSize: 20,
            lineHeight: 32,
        }),
        fontWeight: TypographyFontWeight.MEDIUM,
    },
    [TypographyVariant.PAGE_TITLE]: {
        style: StyleSheet.flatten({
            fontSize: 16,
            lineHeight: 24,
        }),
        fontWeight: TypographyFontWeight.MEDIUM,
    },
    [TypographyVariant.READER]: {
        style: StyleSheet.flatten({
            fontSize: 16,
            lineHeight: 28,
            wordSpacing: 2,
        }),
        fontWeight: TypographyFontWeight.MEDIUM,
    },
    [TypographyVariant.BODY_TITLE]: {
        style: StyleSheet.flatten({
            fontSize: 16,
            lineHeight: 24,
            letterSpacing: 0.5,
        }),
        fontWeight: TypographyFontWeight.MEDIUM,
    },
    [TypographyVariant.BODY]: {
        style: StyleSheet.flatten({
            fontSize: 14,
            lineHeight: 24,
        }),
        fontWeight: TypographyFontWeight.REGULAR,
    },
    [TypographyVariant.CAPTION]: {
        style: StyleSheet.flatten({
            fontSize: 12,
            lineHeight: 20,
        }),
        fontWeight: TypographyFontWeight.REGULAR,
    },
    [TypographyVariant.PARAGRAPH]: {
        style: StyleSheet.flatten({
            fontSize: 14,
            lineHeight: 24,
            letterSpacing: 0.25,
        }),
        fontWeight: TypographyFontWeight.REGULAR,
    },
    [TypographyVariant.BADGE]: {
        style: StyleSheet.flatten({
            fontSize: 10,
            lineHeight: 18,
            letterSpacing: 0,
        }),
        fontWeight: TypographyFontWeight.REGULAR,
    },
    [TypographyVariant.ICON_BADGE]: {
        style: StyleSheet.flatten({
            fontSize: 8,
            lineHeight: 10,
            letterSpacing: 0,
        }),
        fontWeight: TypographyFontWeight.REGULAR,
    },
    [TypographyVariant.EMPTY_MSG]: {
        style: StyleSheet.flatten({
            fontSize: 24,
            lineHeight: 36,
            color: palette.typography.placeholder,
        }),
        fontWeight: TypographyFontWeight.MEDIUM,
    },
    [TypographyVariant.ERROR_MSG]: {
        style: StyleSheet.flatten({
            fontSize: 14,
            lineHeight: 24,
            color: palette.typography.secondary,
        }),
        fontWeight: TypographyFontWeight.REGULAR,
    },
});
