import { addStringPaths } from 'src/components/15-containers/SuperChargedWebView/communication/core/utils';
const EBAPIs = addStringPaths({
    helperViews: {
        htmlEditor: {
            show: true,
        },
        snackbar: {
            show: true,
        },
        topicPicker: {
            show: true,
        },
        bottomDrawer: {
            show: true,
            hide: true,
        },
        imageViewer: {
            show: true,
            hide: true,
        },
        overlay: {
            show: true,
            hide: true,
        },
        hiddenView: {
            render: true,
            clear: true,
        },
        playlist: {
            addToQueue: true,
            overrideQueue: true,
        },
        playlistPlayer: {
            stop: true,
        },
        filePicker: {
            pick: true,
        },
    },
    superChargedWV: {
        loaded: true,
    },
});
export default EBAPIs;
