import React from 'react';
import useHTMLEditor from '../10-atoms/form/inputs/editor/html/HTMLEditor/useHTMLEditor';
import BottomDrawerHelperView from './bottom-drawer/BottomDrawerHelperView';
import PlaylistControllerHelperView from 'src/lib/playlist/PlaylistControllerHelperView';
import HiddenViewHelperView from './hidden-view/HiddenViewHelperView';
import OverlayHelperView from './overlay';
import ImageViewerHelperView from './image-viewer/ImageViewHelperView';
import useTopicPicker from '../10-atoms/form/inputs/topics-input/TopicPickerOverlay/useTopicPicker';
import SnackbarHelperView from './snackbar/SnackbarHelperView';
import useFilePicker from '../10-atoms/file-picker/hooks/useFilePicker';
const HelperViews = ({}) => {
    useTopicPicker();
    useHTMLEditor();
    useFilePicker();
    return (React.createElement(React.Fragment, null,
        React.createElement(HiddenViewHelperView, null),
        React.createElement(PlaylistControllerHelperView, null),
        React.createElement(OverlayHelperView, null),
        React.createElement(BottomDrawerHelperView, null),
        React.createElement(SnackbarHelperView, null),
        React.createElement(ImageViewerHelperView, null)));
};
export default HelperViews;
