import difference from 'lodash/difference';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import TagsInput from '..';
import TagSuggestions from './TagSuggestions';
import useKeyboardNavigation from './useKeyboardNavigation';
import { duplicateRef } from 'src/utils/react';
const AutoCompleteTagsInput = React.forwardRef(({ suggestions, onChange, onFocus, onBlur, value, onInputChange, inputRef, ...tagsInputProps }, ref) => {
    const [{}] = useTheme();
    const [focused, setFocused] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const filteredSuggestions = useMemo(() => {
        return difference(suggestions, value ?? []);
    }, [suggestions, value]);
    const shouldShowSuggestions = focused &&
        tagsInputProps.input.length >= 1 &&
        filteredSuggestions.length > 0;
    const inputContainerRef = useRef(null);
    const shadowInputRef = useRef(null);
    useEffect(() => {
        setShowSuggestions(shouldShowSuggestions);
    }, [shouldShowSuggestions]);
    const handleFocus = useCallbackWithCtx(ctx => {
        setFocused(true);
        ctx.onFocus?.();
    }, { onFocus });
    const handleBlur = useCallbackWithCtx(ctx => {
        setFocused(false);
        ctx.onBlur?.();
    }, { onBlur });
    const handleChange = useCallbackWithCtx((ctx, newTags) => {
        setShowSuggestions(false);
        ctx.onChange?.(newTags);
    }, { onChange });
    const handleTagSelected = useCallbackWithCtx((ctx, tag) => {
        ctx.onChange?.([...ctx.value, tag]);
        ctx.onInputChange('');
        setShowSuggestions(false);
        shadowInputRef.current?.focus();
    }, { onChange, value, onInputChange });
    const [keyboardNavigationState, keyboardNavigationActions] = useKeyboardNavigation({
        data: filteredSuggestions,
        onItemSelected: handleTagSelected,
    });
    return (React.createElement(Column, { ref: ref, style: { position: 'relative' } },
        React.createElement(TagsInput, { ref: inputContainerRef, inputRef: duplicateRef(inputRef, shadowInputRef), value: value, onInputChange: onInputChange, onFocus: handleFocus, onBlur: handleBlur, onChange: handleChange, onKeyPress: keyboardNavigationActions.handleKeyPress, ...tagsInputProps }),
        React.createElement(TagSuggestions, { data: filteredSuggestions, inputContainerRef: inputContainerRef, inputRef: shadowInputRef, selectedTagIndex: keyboardNavigationState.focusedIndex, onTagSelected: handleTagSelected, visible: showSuggestions })));
});
export default AutoCompleteTagsInput;
