import React from 'react';
import { View } from 'react-native';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
import Column from '../../layout/Column';
import Row from '../../layout/Row';
import Typography from '../../typography/Typography';
const TabHeaderItem = ({ label, selected, count, }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator, { selected }, [selected]);
    return (React.createElement(Column, { spacing: 4 },
        React.createElement(Row, { ph: theme.spacing.standard.xs, yAxis: "center", spacing: theme.spacing.standard.xs },
            React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM, color: selected
                    ? theme.palette.primary.main
                    : theme.palette.typography.primary }, label),
            typeof count === 'number' && (React.createElement(Row, { bg: theme.palette.primary.main, xAxis: "center", yAxis: "center", ph: theme.spacing.standard.xs, style: styles.countContainer },
                React.createElement(Typography, { color: theme.palette.primary.contrast, variant: TypographyVariant.BADGE, fontWeight: TypographyFontWeight.MEDIUM }, count)))),
        React.createElement(View, { style: styles.indicator })));
};
const stylesGenerator = createThemedStyle(({ theme }, { selected }) => ({
    container: {
        paddingHorizontal: theme.spacing.standard.xs,
    },
    indicator: {
        height: 0,
        borderWidth: 2,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderColor: selected ? theme.palette.primary.main : 'transparent',
        backgroundColor: selected ? theme.palette.primary.main : 'transparent',
    },
    countContainer: {
        minWidth: 20,
        borderRadius: 10,
    },
}));
export default withPress(TabHeaderItem);
